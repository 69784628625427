import React from 'react';
import Header from '../../Header/Header';
import Card from '../../Card/Card';

const India = () => {
    const businessesPage = {
        "businesses01": { id: "businesses01", title: "Stop Using Passwords", description: "You’ll never have to remember passwords and there will be nothing for hackers to steal.", reverse: false, img: { src: 'assets/images/password.png' }, showButtons: { "btn01": { title: "Login", navigateTo: "/login", type: "light" }, "btn02": { title: "Create Free Account", navigateTo: "/signup" } } },
        "businesses02": { id: "businesses02", title: "Protect Your Privacy", description: "Your personal data is encrypted and stored on your local device. Even we cannot access it. Only you can.", reverse: true, img: { src: 'assets/images/protect.png' }, showButtons: { "btn01": { title: "Login", navigateTo: "/login", type: "light" }, "btn02": { title: "Create Free Account", navigateTo: "/signup" } } },
        "businesses03": { id: "businesses03", title: "Take Anonymous Surveys", description: "Participate in surveys without sharing your identity. Submit a single authenticated response to keep the system robust and avoid fraud.", reverse: false, img: { src: 'assets/images/survey.png' }, showButtons: { "btn01": { title: "Login", navigateTo: "/login", type: "light" }, "btn02": { title: "Create Free Account", navigateTo: "/signup" } } },
        "businesses04": { id: "businesses04", reverse: true, img: { src: 'assets/images/san-francisco.png' }, },
    }
    return (
        <div>
            <Header />
            <div className="container-fluid scrollContainer">
                <div className="card-padding"></div>
                {Object.keys(businessesPage).map((businessesId) => {
                    var item = businessesPage[businessesId];
                    return <div className="slide" key={businessesId}><Card {...item} /></div>
                })}
            </div>
        </div>
    );
}

export default India;



