import { Routes, Route, HashRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./Pages/Home/Home";
import India from "./Pages/India/India";

export default function App() {
   return (
      <HashRouter>
         <Routes>
            <Route index element={<Home />} />
            <Route path="in" element={<India />} />
         </Routes>
      </HashRouter>
   );
}
