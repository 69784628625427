import React from 'react';

const Card = (props) => {

    const { title, description, img, reverse, showSocialMediaBtn, id } = props;
    let order = "", extradiv = "extradiv", col1 = "col-lg-6", col2 = "col-lg-4", personImg = "img-fluid w-100", titleClass = "inter-bold-2", descriptionClass = "inter-regular-1";

    if (reverse) {
        extradiv += " order-lg-2"
        order = "order-lg-3";
        col1 = "col-lg-6";
        col2 = "col-lg-4";
    }
    if (showSocialMediaBtn) {
        personImg = "person-img ";
        col1 = "col-lg-3 col-xl-2";
        col2 = "col-lg-6 col-xl-5";
        titleClass += " center-align";
        descriptionClass += " center-align"
    }
    return (
        <div>



            {
                id == "businesses04" ?
                    <div className="row justify-content-sm-center custom-card align-items-center footer-card">
                        <div className={`${col1} ${order} d-flex justify-content-center px-0`}>
                            <img src={img.src} className={personImg} />
                        </div>
                        <div className={extradiv}></div>
                        <div className={`${col2} d-flex justify-content-center px-0`}>
                            <div className="card-description">
                                <div className='row '>

                                    <div>
                                        <p className="footer-head inter-bold-1">United States</p>

                                        <p className='inter-regular-2'> Avatar Inc
                                        </p>
                                        <p className='inter-regular-2'>1049 El Monte Avenue Suite C 649</p>
                                        <p className='inter-regular-2'> Mountain View, CA 94040</p>
                                        <p className='inter-regular-2'> United States</p>

                                    </div>
                                    <div>
                                        <p className='footer-head  inter-bold-1'> India </p>
                                        <p className='inter-regular-2'> Office 301 Westport
                                        </p>
                                        <p className='inter-regular-2'>Pan Card Club Road </p>
                                        <p className='inter-regular-2'>Baner, Pune 411045</p>
                                        <p className='inter-regular-2'> Maharashtra, India </p>

                                    </div>
                                    <div>
                                        <p className="footer-head inter-bold-1">Privacy Policy</p>

                                        <a href='https://www.avatar.me/privacy-policy' className='link' target='_blank'>
                                            <p className='inter-regular-2'> https://www.avatar.me/privacy-policy   </p>
                                        </a>
                                    </div>
                                    <div>
                                        <p className="footer-head inter-bold-1">Patent</p>

                                        <a href='https://patents.google.com/patent/US20210091937A1/' className='link' target='_blank'>
                                            <p className='inter-regular-2'> https://patents.google.com/patent/US20210091937A1/
                                            </p>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    :
                    <div className="row justify-content-sm-center custom-card align-items-center">
                        <div className={`${col1} ${order} d-flex justify-content-center px-0`}>
                            <img src={img.src} className={personImg} />
                        </div>
                        <div className={extradiv}></div>
                        <div className={`${col2} d-flex justify-content-center px-0`}>
                            <div className="card-description">
                                <p className={titleClass}>{title}</p>
                                <p className={descriptionClass}>{description}</p>
                                <div className="d-inline d-flex justify-content-lg-start gaps">
                                    <a href="https://apps.apple.com/us/app/avatar-identity/id1601362763" target="_blank">
                                        <img src={`${process.env.PUBLIC_URL}assets/images/SVG/apple-black.svg`} className='apple' alt="play-store" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=me.avatar.app" target="_blank">
                                        <img src={`${process.env.PUBLIC_URL}assets/images/SVG/google-black.svg`} className='google' alt="app-store" />
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>

            }



        </div>)
}

export default Card;