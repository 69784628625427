import React from 'react';
 
const Logo = ( ) => {
    return (
             <div className="logo">
                <div className="position-relative">
                    <img src={'assets/images/SVG/avatar-wordmark.svg'} height="32" className="d-block wordmark" />
                 </div>
            </div >
     )
}

 class Header extends React.Component {
    constructor(props) {
        super(props)
        this.props = props;
    }
    render() {
        return (
            <div className="headerRow fixed-top">
                <div className="container-fluid">
                    <div className="headerContainer row justify-content-between align-items-center">
                        <div className="col-lg-4 d-flex justify-content-lg-start justify-content-center">
                            <Logo  />
                        </div> 
                     
                    </div>
                </div>
            </div>
        )
    }
}
 

export default Header;
