import React from "react";


function Home() {
    return (
        <div className="body-container">
            <img src={`${process.env.PUBLIC_URL}assets/svgs/avatar-wordmark.svg`} height="48" width="273.53" alt="Avatar-logo" className="avatar-logo" />
            <p className="text">Privacy for people, authenticity for the world.</p>
            <div className="footer">
                <div>
                    <a href="https://twitter.com/the_avatar_app" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}assets/svgs/twitter-button.svg`} height="40" className="follow-on-twitter" />
                    </a>
                    <a href="https://www.linkedin.com/company/avatarinc/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}assets/svgs/linkedin-button.svg`} height="40" className="follow-on-linkedin" />
                    </a>
                </div>
                <p className="footerText">Avatar Inc.</p>
            </div>
        </div>
    );
};

export default Home;